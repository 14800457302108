.App {
  text-align: center;
}

.App-logo {
  position: absolute;
  left: 0;
  width: 180px;
  padding-left: 20px;
  object-fit: contain;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swal2-styled.swal2-default-outline.confirm-button-class:focus{
  box-shadow: 0 0 0 3px rgb(173 142 100 / 50%);
}

#splash-screen{
  display: flex;
  justify-content: center;
  height: 100vh; 
  align-items: center;
  background: rgb(245 241 237 / 50%);
  position: fixed;
  width: 100vw; 
  z-index: 1301;
}

#splash-screen.hidden{
  display: none;
}

.mapboxgl-popup-close-button{
  border: none;
  outline: none;
}

.mapboxgl-marker{
  cursor: pointer;
}
